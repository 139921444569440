import {
  Button,
  makeStyles,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core';

import { FontFamily } from '@/themes/typographyTheme';
import { colorTheme } from '@/themes/colorTheme';

export const DRAWER_WIDTH = 240;

export const useStyles = makeStyles(({ palette }) => ({
  archivedHeader: {
    '& p': {
      color: '#000',
      fontSize: '12px',
      paddingLeft: '10px',
    },
    '& svg': {
      fontSize: '20px',
    },
    paddingLeft: '58px',
  },
  checked: {},
  collapse: {
    width: '100%',
  },
  divider: {
    backgroundColor: palette.grey[5],
  },
  drawer: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08);',
    flexShrink: 0,
    height: '100vh',
    width: DRAWER_WIDTH,
  },
  drawerContainer: {
    overflow: 'hidden',
  },
  drawerFilterButton: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    textTransform: 'capitalize',
  },
  drawerListSubheader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  drawerListWrapper: {
    borderBottom: `1px solid ${palette.grey[5]}`,
    marginTop: '-16px',
    overflow: 'auto',
    padding: '16px 24px',
  },
  drawerPaper: {
    gap: '16px',
    position: 'relative',
    width: DRAWER_WIDTH,
  },
  drawerSwitch: {
    width: '128px',
  },
  icon: {
    fontSize: '18px',
    marginRight: '4px',
  },
  item: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  itemActive: {
    '& > $itemLink': {
      backgroundColor: colorTheme.orangeScale[5],
      fontFamily: FontFamily.Pontiac,
      fontWeight: 'bold',
    },
  },
  itemLink: {
    '&:hover': {
      '& $starEmpty': {
        visibility: 'visible',
      },
      backgroundColor: palette.grey[5],
    },
    alignItems: 'center',
    display: 'flex',
    lineHeight: '2em',
    margin: '2px 0',
    padding: '2px 0',
    width: '100%',
  },
  itemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  loader: {
    marginBottom: '10px',
    position: 'relative',
    width: '100%',
  },
  logo: {
    '&:hover': {
      cursor: 'pointer',
    },
    height: '32px',
    margin: '24px 0 24px 24px',
    position: 'static',
    width: '180px',
  },
  nested: {
    paddingLeft: '32px',
  },
  nestedArchived: {
    '& p': {
      fontSize: '12px',
    },
    paddingLeft: '54px',
  },
  radio: {
    '&$checked': {
      color: palette.primary.main,
    },
  },
  starButton: {
    padding: '8px',
  },
  starEmpty: {
    visibility: 'hidden',
  },
  starFill: {
    visibility: 'visible',
  },
  subheader: {
    position: 'relative',
  },
  sublist: {
    '& $itemLink': {
      color: palette.grey[100],
      paddingLeft: '8px',
    },
    padding: 0,
  },
}), {
  index: 2,
  name: 'MuiCustomDrawerStyle',
});

export const ArchiveButton = withStyles({
  root: {
    fontFamily: FontFamily.FiraSans,
    fontSize: '12px',
    justifyContent: 'flex-start',
    marginTop: '8px',
    paddingLeft: '16px',
    textTransform: 'none',
    transition: '0s',
    width: '100%',
  },
})(Button);

export const DrawerTabs = withStyles({
  root: {
    margin: '0 16px',
  },
})(Tabs);

export const DrawerTab = withStyles({
  root: {
    minWidth: '50%',
  },
})(Tab);
