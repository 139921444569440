export enum FontFamily {
  FiraSans = 'Fira Sans',
  Pontiac = 'Pontiac',
}

export const typographyTheme = {
  body1: {
    fontFamily: FontFamily.FiraSans,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  body2: {
    fontFamily: FontFamily.FiraSans,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
  },
  button: {
    fontFamily: FontFamily.Pontiac,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
  },
  h1: {
    fontFamily: FontFamily.Pontiac,
    fontSize: '34px',
    fontWeight: 700,
    lineHeight: '34px',
  },
  h2: {
    fontFamily: FontFamily.Pontiac,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  h3: {
    fontFamily: FontFamily.Pontiac,
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  h4: {
    fontFamily: FontFamily.Pontiac,
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '18px',
  },
  h5: {
    fontFamily: FontFamily.Pontiac,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '16px',
  },
  h6: {
    fontFamily: FontFamily.Pontiac,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '14px',
  },
  subtitle1: {
    fontFamily: FontFamily.FiraSans,
    fontSize: '16px',
    lineHeight: '16px',
  },
  subtitle2: {
    fontFamily: FontFamily.FiraSans,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
  },
} as const;

export const pontiacRegular = {
  fontFamily: 'Pontiac',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url('/fonts/pontiac/pontiac_regular.woff') format('woff'),
    url('/fonts/pontiac/pontiac_regular.woff2') format('woff2'),
    url('/fonts/pontiac/pontiac_regular.ttf') format('truetype');
  `,
} as const;

export const pontiacLight = {
  fontFamily: 'Pontiac',
  fontStyle: 'normal',
  fontWeight: 200,
  src: `
    url('/fonts/pontiac/pontiac_light.woff') format('woff'),
    url('/fonts/pontiac/pontiac_light.woff2') format('woff2'),
    url('/fonts/pontiac/pontiac_light.ttf') format('truetype');
  `,
} as const;

export const pontiacBold = {
  fontFamily: 'Pontiac',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    url('/fonts/pontiac/pontiac_bold.woff') format('woff'),
    url('/fonts/pontiac/pontiac_bold.woff2') format('woff2'),
    url('/fonts/pontiac/pontiac_bold.ttf') format('truetype');
  `,
} as const;

export const firaSansRegular = {
  fontFamily: 'Fira Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url('/fonts/firasans/firasans_regular.woff') format('woff'),
    url('/fonts/firasans/firasans_regular.woff2') format('woff2'),
    url('/fonts/firasans/firasans_regular.ttf') format('truetype');
  `,
} as const;

export const firaSansLight = {
  fontFamily: 'Fira Sans',
  fontStyle: 'normal',
  fontWeight: 200,
  src: `
    url('/fonts/firasans/firasans_light.woff') format('woff'),
    url('/fonts/firasans/firasans_light.woff2') format('woff2'),
    url('/fonts/firasans/firasans_light.ttf') format('truetype');
  `,
} as const;

export const firaSansBold = {
  fontFamily: 'Fira Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    url('/fonts/firasans/firasans_bold.woff') format('woff'),
    url('/fonts/firasans/firasans_bold.woff2') format('woff2'),
    url('/fonts/firasans/firasans_bold.ttf') format('truetype');
  `,
} as const;
