import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

interface ConfirmDialogProps {
  title: string;
  content: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: () => void;
}

export const Confirm = (props: ConfirmDialogProps) => {
  const {
    title,
    content,
    open,
    setOpen,
    onConfirm,
  } = props;

  const onClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          No
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
